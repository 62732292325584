// 产品相关接口
import request from '../utils/request'
// import qs from 'qs'
// 0010118-通知公告-分页
export function getNotice(params = {}) {
    return request({
        method: 'GET',
        url: 'sys/notice',
        params
    })
}
// 0010131-资讯-热点
export function getHotspot(params = {}) {
    return request({
        method: 'GET',
        url: 'article/hotspot',
        params
    })
}

// 0010130-资讯-分页
export function getArticle(params = {}) {
    return request({
        method: 'GET',
        url: 'article',
        params
    })
}
//0010132-资讯-详情
export function getArticleDetail(id) {
    return request({
        method: 'GET',
        url: `article/${id}`,
    })
}