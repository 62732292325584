<template>
  <div class="main">
    <header-t/>
    <nav2/>
    <div class="cont">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-if="type === 'notice'"
        >公告
        </el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="type === 'news'">资讯</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="item mar-bottom">
        <el-row :gutter="20">
          <el-col :span="type=== 'notice'?24:18">
            <el-card shadow="nerve" class="card">
              <div class="title_wrap">
                <h3 class="art-title">{{ type === 'notice' ? info.noticeTitle : info.title }}
                </h3>
                <p class="date">发布时间：{{ type === 'notice' ? info.createTime : info.publishTime }}</p>
              </div>
              <div class="content">
                <div class="conxt" v-html="type === 'notice' ? info.noticeContent : info.body"></div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card v-if="type=== 'news'" shadow="nerve" class="card">
              <div class="panel">
                <div class="tit">
                  <h3>编辑推荐</h3>
                </div>
                <div class="body">
                  <ul class="dynamic">
                    <li
                        v-for="item in recommendData"
                        :key="item.id"
                        @click="goDetail(item.id)"
                    >
                      {{ item.title }}
                    </li>
                  </ul>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import HeaderT from "@/components/HeaderT";
import Nav2 from "@/components/Nav2";
import Footer from "@/components/Footer";
import {notice} from '@/api/common'
import {getHotspot, getArticleDetail} from '@/api/index'
import "quill/dist/quill.snow.css";

export default {
  name: "Article",
  components: {
    HeaderT,
    Nav2,
    Footer,
  },
  data() {
    return {
      info: {},
      noticeId: null,
      newsId: null,
      type: '', // 类型
      recommendData: []
    }
  },
  created() {
    console.log(this.$route.query.type)
    if (this.$route.query.type) {
      this.type = this.$route.query.type
      // console.log(this.type);
    }
    this.getDataHot(2)
  },
  mounted() {
    this.noticeId = this.$route.query.noticeId
    this.newsId = this.$route.query.newsId
    if (this.type === 'notice') {
      this.getDetail()
    }
    if (this.type === 'news') {
      this.getNewsDetail()
    }
  },
  methods: {
    getDetail() {
      notice(this.noticeId).then(res => {
        if (res.data.code === 0) {
          console.log(res);
          this.info = res.data.data
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    getNewsDetail() {
      getArticleDetail(this.newsId).then(res => {
        if (res.data.code === 0) {
          console.log(res);
          this.info = res.data.data
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    // 获取资讯-热点
    getDataHot(typeId) {
      getHotspot({type: typeId}).then((res) => {
        // type:0 轮播图; 1 热门焦点; 2 编辑推荐
        console.log(res)
        if (res.data.code === 0) {
          this.recommendData = res.data.data.rows
        }
      })
    },
    goDetail(id) {
      // console.log(id);
      if (this.type === 'news') {
        this.newsId = id
        this.getNewsDetail()
      }

    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main {
  height: 100%;
  background-color: #f5f5f5;

  .cont {
    width: 1170px;
    margin: 20px auto;

    .item {
      margin-top: 20px;

      .card {
        .title_wrap {

          border-bottom: 4px #eee solid;
          height: 120px;

          .art-title {
            font-size: 28px;
            font-weight: 700;
            line-height: 1.5;
            margin-top: 30px;
            // padding-bottom: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            /* 显示省略号 */
            text-overflow: ellipsis;
          }
        }

        .date {
          margin-top: 8px;
          text-align: right;
          color: #666;
          font-size: 14px;
        }

        .content {
          min-height: 400px;

          .conxt {
            padding-top: 40px;
            text-align: left;
            text-indent: 2em;
            color: #333;

            /deep/ .ql-align-right {
              text-align: right;
            }

            /deep/ p {
              text-indent: 2em;
              line-height: 2;

              img {
                width: 100%;
              }


              /deep/ .ql-align-justify {
                border: 1px #f00 solid;

                /deep/ img {
                  width: 100% !important;
                }
              }
            }
          }
        }
      }

      .panel {
        .tit {
          text-align: left;
          line-height: 30px;
          border-bottom: 1px solid #f5f5f5;
        }

        .body {
          padding: 15px 0;

          .media-left {
            display: table-cell;
            vertical-align: top;
            padding-right: 15px;

            .avatar-md {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              margin: 5px;
            }
          }

          .media-member {
            display: table-cell;
            vertical-align: top;

            .avatar-md {
              float: left;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              margin: 5px;
            }
          }

          .dynamic {
            margin-top: 5px;

            li {
              text-align: left;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: normal;
              color: #666;
              border-bottom: 1px #eee dashed;
              height: 30px;
              line-height: 30px;
              margin: 5px 0;
            }

            li:hover {
              color: #b7090a;
              cursor: pointer;
            }
          }

          .media-right {
            display: table-cell;
            vertical-align: top;
            font-size: 14px;

            .title {
              margin: 10px 0 10px 0;
              text-align: left;
            }

            .content {
              color: #919191;
            }
          }
        }
      }
    }

    .mar-bottom {
      margin-bottom: 20px;
    }
  }
}


</style>